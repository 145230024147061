.page.realisations-appli {
    .grid.appli {
        grid-template-columns: 1fr 1fr;
        article {
            display: flex; flex-direction: column;
            aside {
                grid-area: auto;
                padding: 0; margin: auto;
            }
            img {border: 0; max-width: max(500px, 30vw); object-fit: contain; object-position: bottom; }
        }
    }
    @media screen and (max-width: 1024px) {
        .grid.appli {
            grid-template-columns: 1fr;
            gap: 1rem;
            article:first-of-type > div:first-of-type {
                border-top: 0;
            }
            article {
                > div:first-of-type {
                    padding: 1rem 0 0 0 ; margin: auto;
                    border-top:1px gray solid;
                }
                img {max-width: 40%; ; object-position: top; }
                h3 {font-weight: normal; font-size: 1.5rem; }
            }
        }
    }
}