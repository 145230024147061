@media (min-width: 48em) {
    .header {
        .menu-www {
            max-height: none;
        }
        .menu-icon {
            display: none;
        }
    }
}
@media only screen and (max-width: 1023px) {
    .banner {
        > div {
            flex-direction: column; height: 100%;
            border-radius: 40% / 50%;
            img { max-width: none; }
            h2 {
                font-size: 3rem;
                margin-bottom: 2rem;
            }
        }
    }
    header {height: 5rem}
    main {padding-top: 7rem}
    main .content {
        padding: 1rem;
    }


    footer {
        margin: 1rem 0;
        .container {
            grid-template-columns: 1fr 1fr; font-size: .85rem;
            padding:0.5rem 1rem;
            div {padding-left: 1rem}
            div:nth-of-type(1) {  grid-row: auto/span 2;}
            div:nth-of-type(2) { border-right: none; }
            div:nth-of-type(3) { margin-top: auto; }
            a {display: inline-block; line-height: 1.5rem;}
            li {padding:0 0.5rem; white-space: normal}
        }
    }
}
@media only screen and (max-width: 480px) {
    nav {
        figure {
            img {
                object-fit: contain;
                height: 100px;
                width: 100px;
            }
        }
    }
    main {padding-top: 5rem}
    section {
        article {
            h2 {
                font-size: 1.5rem;
            }
            .texte {
                font-size: 1rem;
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    header {
        .burger {
            display: none;
        }
        .menu-www {
            display: flex;
        }
        nav .submenu-www {
            display: flex;
        }
        .menu-mobile {
            display: none;
        }
        .search-mobile {
            display: none;
        }
    }
    .picto {
        background-size: 200px;
    }
}
@media only screen and (min-width: 1600px) {
    .header {
        li {
            a {
                font-size: 16px;
            }
        }
    }
    .page .banner {
        h2 {
            font-size: 60px;
        }
    }
    .page.realisations .banner .content h3 { font-size: 50px;
        section article {
            .texte {
                font-size: 20px;
            }
            h2 {
                font-size: 34px;
                line-height: 4rem;
            }
        }
    }
}
