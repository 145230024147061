
.search-panel {
    display: none;
    padding-top: 121px;
    background-color: $bg_green_3;
}

.show-search {
    .search-panel {
        display: flex; flex-direction: column;
        width: 100vw; height: calc(100vh - 121px);
        align-items: center;
        transition: width 0.2s;

        form { display: flex;flex-wrap: wrap; justify-content: center;  padding: 2rem; }
        .action.close {align-self: end; padding: 2rem 1rem; top: 50px}

        label { margin-bottom: 2rem; font-weight: 400;display: block; flex-basis: 100%; }

        input[type="text"] {
            flex:1; padding: 8px; border-color: transparent; margin: 0; line-height: 2rem;
            font-size: max(2rem, 1.2vw); color: darkgrey; width: 100%;
        }

        .action.search {
            display: inline-block;
            padding: 10px 20px;
            background-color: $bg_green;
            color: $bg_white;
            line-height: 3rem;
            font-size:2rem;
            cursor: pointer;
            &:hover {
                background-color: $bg_green;
            }
        }

    }
    .search-results {
        ul {
            margin-top:2rem;
            li {list-style: none; line-height: 2rem}
            a {
                text-decoration: none;
                &:hover {text-decoration: underline}
            }
        }
    }
    main {display: none}
    footer {display: none}

    @media only screen and (max-width: 1023px) {
        .search-panel {width: auto;}
        form {max-width: 100%; padding: 1rem;}
        .search-results {padding: 0 2rem}
    }
}