.page.realisations-web {
    @media screen and (max-width: 1024px) {
        .banner .container h3 {width:40px;}
    }
}


.page.realisation {
    .banner {
        background-color: $bg_blue; box-sizing: border-box;
        height: 500px; padding-top:0;
        .container {width: 1600px; margin: 0; padding: 70px 3rem 0; display: block; height: 100%}

        img {
            max-height: calc(600px - 5rem);
            max-width: none;
            height: 110%;
            width: 100%;
            padding: 0; margin-top: 0;
            position: relative;  object-fit: cover;
            object-position: top left;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
        }
    }
    main .content {
        padding: 3rem;
        h1 {font-weight: bold;  font-family: $font-family_advent; padding-bottom: 2rem; }
        article{
            grid-template-rows: auto 5fr;
            &.alternate {
                grid-template-columns: 1fr 2fr;
                grid-template-rows: auto 5fr;
                padding: 2rem;
                h1 {grid-area: 1 / 2 / 1 / 2 ;}
                .texte {grid-row: 2 / 3 ; grid-column: 2 / 2}
                aside{grid-row: 1 / 3; grid-column: 1 / 2; }
            }

            &.summary  {
                display: flex; flex-direction: column;
                img {max-width: 800px; margin: auto}

            }
        }

    }
}

@media screen and (max-width: 1024px) {
    .page.realisation {
        .banner {
            padding: 0;
            height: min(500px, 30vh);
            //padding-top:5rem;
            .container {
                margin: 0;
                padding: 70px 1rem 0;
            }
            img {position: relative; height: 100%; object-fit: cover; }
        }
        main .content {
            padding: 2rem;
            article {
                display: flex; flex-wrap: wrap; flex-direction: column;
                &.alternate { padding: 1rem; }
                aside{padding-top: 1rem; width: 100%;
                img {width: 100%}}
            }
        }
    }
}
@media (orientation: landscape) {
    .page.realisation {
        .banner {
            padding: 0;
            height: 500px;
        }
    }
}