.scrolled {
    header  {
        border-bottom:1px #ccc solid;
        transition: .5s all;
        .logo img {height: 75px;
            transition-duration: .2s;}
    }
}

.opened {
    main {display: none}
    footer {display: none}
}

header {
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
    position: fixed;
    width: 100%;
    z-index: 30;
    background-color: $bg_white;
    display: flex;
    //height: 122px
    flex-wrap: wrap;

    nav {
        display: grid;
        padding: 0 2rem;
        grid-template-columns: 300px auto;
        background-color: $bg_white;
        max-width: 1600px; margin: auto;
        width: 100%;
        a:hover {text-decoration: none;}
        li {
            width: 100%;
            a {
                text-decoration: none;
                font-size: min(.9vw, 1rem);
                color: $color_black;
                transition: .6s;
                font-weight: 600;
            }
        }
        .summary {
            a {
                font-size: 1.2rem;
            }
        }
        .sub {
            opacity: 0; height: 0; display: none;
            a{display: block}
        }
        .focus {
            opacity: 0;
            height: 0;
            display: none;

        }
        .active {
            .sub {
                display: flex;
                opacity: 1;
                height: auto;
                transition: all .5s ease-in-out;
                a {
                    font-weight: 400;
                    &:hover {font-weight: 600;}
                }
                ul {flex-direction: column; padding-left:1rem; padding-bottom:1rem;
                    li:before {content: ' - '; padding-right: .2rem;}
                }
            }
            .focus {
                display: flex;
                opacity: 1;
                height: auto;
                transition: all .5s ease-in-out;
                max-width: calc(100% - 4rem);
            }
            > a {
                color: $color_blue;
                //font-weight: 700;
                position: relative;
            }
        }
        .focus {
            h3 {
                color: $color_green; font-size: 1.2rem; font-variant: small-caps; padding-bottom: 0.2rem;
            }
            p {
                font-size: 1rem; font-weight: 600; line-height: 1.5rem;
            }
            a {font-size: .8rem }
        }


        ul {
            font-size: .9em;
            background-color: $bg_white;
            display: flex;
            align-items: center;
            list-style: none;
            li {
                a {
                    color: $color_black;
                    text-decoration: none;
                    transition: .2s;
                }
                &.contact-search {display: flex; gap: 0 2rem; padding:0 1rem;}
            }
        }
        .menu-www {
            grid-area: 1/2/2/ 5;
            justify-items: start;
            height: 5rem;

            li {text-align: center; }
            .current a { color: $color_blue; font-weight: 700; }

            .active {
                a::after {
                    position: absolute;
                    content: '';
                    width: calc(100% - 3rem);
                    height: 3px;
                    bottom: -3px;
                    left: 3rem;
                    border-bottom: #62B34F 3px solid;
                }
            }
            :not(.active):not(.current) {
                a {
                    &:hover {
                        &::after {
                            transform: scaleX(1);
                            transform-origin: bottom left;
                        }
                    }
                    display: inline-block;
                    position: relative;
                    color: $color_black;
                    transition: .2s;
                    &::after {
                        content: '';
                        position: absolute;
                        width: calc(100% - 1rem);
                        transform: scaleX(0);
                        height: .1rem;
                        bottom: 0;
                        left: 1rem;
                        background-color: $bg_black;
                        transform-origin: bottom right;
                        transition: transform 0.25s ease-out;
                    }
                }
            }
        }

        .submenu-www {
            grid-area: 2 / 2 / 2 / span 3;
            display: none;
            flex-direction: column;

            > .active {
                display: flex;
                margin-bottom: 2rem;
                transition: all .2s ease-in-out;

                .sub {
                    flex: 1; display: flex; flex-direction: column; justify-items: start;
                    padding: 0 3rem; flex-wrap: nowrap;
                    a:hover {color:$color_blue;}
                }
                .focus {
                    flex: 3; border-left: 2px black solid;
                    padding: 0 4rem;
                    margin-right: auto;
                    flex-direction: row;
                    border-top: 0;

                    .summary {max-width: 300px; font-weight: bold; font-size: 1rem; text-align: right; padding-right: 1rem;}
                }

            }
        }
    }
    .logo {
        font-size: 2em;
        grid-area: 1 / 1 / 3 / 1;
        text-decoration: none;
        text-align: start;
        flex-basis: 250px;
        img {
            height: 100%;
            max-width: 100%;
            max-height: 100px;
        }
    }
    .burger {
        grid-area: 1 / 1;
        max-width: 100px;
        display: flex;
        flex-direction: column;
        gap:.5rem;
        justify-items: start;
        cursor: pointer;
        align-self: center;
        margin-right: auto;
        user-select: none;

        &.open {
            .navicon:first-of-type {opacity: 0; transition: opacity 0s;}
            .navicon:nth-of-type(2) {transform: rotate(-45deg) scaleY(1.1); top:5px}
            .navicon:last-of-type{transform: rotate(45deg); bottom:6px}
        }

        .navicon {
            transition: .3s cubic-bezier(.8, .5, .2, 1.4);
            //transition-duration: 500ms;

            margin: auto;
            background: #333;
            display: block;
            height: 4px;
            position: relative;
            width: 40px;
        }
    }
    .menu-mobile {
        grid-row: 2;
        grid-column: 1 / span 3;

        user-select: none;
        display: flex;
        flex-direction: column;
        //width: calc(100vw - 4rem);
        padding: 2rem;
        justify-content: start;
        .sub {
            flex-direction: column;
            padding: 0 0 2rem 8rem;
            a {
                font-size: 2rem;
                font-weight: normal;
                margin-bottom: 1rem;
                display: inline-block;

            }
        }
        .focus {
            flex-direction: column;
            padding-top: 1rem;
        }
        .summary {
            p {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 1rem;
            }
        }
        a {
            font-size:  1.5rem; // min(6vw, 2rem);
            margin-bottom: 2rem;
            display: inline-block;
        }
        .sub {
            padding: 0 0 2rem 2rem;
            a {font-size: 1.2rem;}
        }
        .active {

            > a {
                &::after {
                    position: absolute;
                    content: '';
                    width: calc(100% - 3rem);
                    height: 3px;
                    top: 2.5rem;
                    left: 3rem;
                    border-bottom: #62B34F 3px solid;
                }
            }
        }
    }
    .search-mobile {
        grid-area: 1 / 3;
        margin-left: auto;
        align-self: center;
    }
}


@media only screen and (max-width: 1023px) {
    header {
        width: 100vw; height: 5rem;
        nav {grid-template-columns: 120px 1fr 1fr;}
        .burger.open {
            .menu {
                display: flex; }
        }
        .logo {grid-row: 1; grid-column: 2; text-align: left; max-height: 90%; margin: auto 0;}
        .menu-www {
            &:not(.open) {  display: none; }
        }
        .menu-mobile {
            &:not(.open) { display: none; }
        }
    }
    nav {
        grid-template-columns: 100px 1fr 100px;

        ul { font-size: .7em; }
    }
}
@media only screen and (max-width: 599px) {
    .scrolled header .logo img {height: auto;}
    header .burger {
        .navicon {height: 3px;}
    }
    .search-mobile {
        i {font-size: 2rem}
    }
}