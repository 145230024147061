.page.home {
    .banner {
        background-color: $bg_blue;
        > div {
            background-color: $bg_white;
            padding: 1em 3rem 1rem 100px;
            width: 90%;
            flex-direction: row;
        }
        h2, img {margin: auto;}
        object {
            object-fit: contain;display: flex;
            width: min(80vw, 600px);  height: 100%;
        }
        .focus {
            color: $color_green;
            position: relative;
            &::after {
                content: '';
                width: calc(100% - 6vw);
                display: inline-block;
                position: absolute;
                height: 3px;
                right: 0;
                bottom: 0;
                background-color: $bg_black;
            }
        }
    }
    .content {
        section {padding-bottom: 1rem;}
        article {
            max-height: calc(400px - min(40vw, 200px)/2 - 1rem) ; // toDO

            width: 100%; display: flex; justify-content: center;
            align-items: center; height: 400px; position: relative;

            .texte {
                //padding: 200px 2rem 0 2rem;
                width: 70%;
            }
            &:nth-child(odd) {
                .line-project {
                    left: calc(15% - 60px);
                    background-image: url('/img/branch-a.svg');
                }
            }
            &:nth-child(even) {
                .line-project {
                    background-image: url('/img/branch-b.svg');
                }
            }

            .line-project {
                position: absolute;
                width: 100px;
                height: calc(400px -  max(5vw, 150px) - 1rem);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                left: calc(15% - 60px);
                top:  calc(max(6vw, 200px) - 2rem);
            }
            .texte {
                display: flex;
                flex-direction: column;
                font-size: min(calc(7px + .7vw), 1.5rem);
            }
            h1, h2 {
                font-family: 'advent', serif;
                font-size: min(4vw, 3rem);
                padding-bottom: 1rem;
                font-weight: 500;
            }

        }

    }


    .picto {
        flex-basis: 20%;
        z-index: 2;
        text-align: center;
        //background-size: 200px;
        background-position: center;
        background-repeat: no-repeat;
        width: fit-content;
        min-height: 200px;
        background-size: max(6vw, 140px);
    }
    .picto.outils {
        background-image: url('/img/pictos/outils_1.svg');
        &:hover {
            background-image: url('/img/pictos/outils_2.svg');
        }
    }
    .picto.collab {
        background-image: url('/img/pictos/mains_1.svg');
        &:hover {
            background-image: url('/img/pictos/mains_2.svg');
        }
    }
    .picto.eureka {
        background-image: url('/img/pictos/solutions-originales_1.svg');
        &:hover {
            background-image: url('/img/pictos/solutions-originales_2.svg');
        }
    }
    .picto.user {
        background-image: url('/img/pictos/specialistes_1.svg');
        &:hover {
            background-image: url('/img/pictos/specialistes_2.svg');
        }
    }

    @media only screen and (max-width: 1023px) {
            .banner {
                padding: 1rem;
                > div {
                    padding: 0; justify-content: center; flex-direction: column;
                    width: 100%; border-radius: 10%;
                    h2 { text-align: center; font-size: max(5vw, 16px) ; order: 2; margin: 1rem 0;}
                    > * {margin: auto}
                    img {width: auto;}
                }
            }
            .picto { background-origin: content-box; back-ground-size: 0 2rem;}
        .content {padding: 0 1rem;}
            section {
                article {
                    h2 { font-size: 2rem; }
                    .texte { font-size: 1rem;}
                }
            }

    }


    @media only screen and (max-width: 600px) {
        section.content {
            article {
                column-gap: 1rem;
                height: auto;
            }
        }
        .picto { background-size: 100% }
        .line-project {
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            width: 15vw;
            height: 25vh;
            position: absolute;
            top: 16vh;
            left: 5vw !important;
        }

    }
}