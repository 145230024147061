$color_black: black;
$color_green: #62B34F;
$color_blue: #2E3192;
$color_white: white;
$font-family_kohinoor: 'Kohinoor';
$font-family_advent: 'advent', serif;
$bg_white: #fff;
$bg_blue: #2E3192;
$bg_blue_2: #ddddef;
$bg_white: white;
$bg_black: black;

$bg_green: rgb(157, 207, 152);
$bg_green_2: #A9D69E;
$bg_green_3: #EAF6E8;
$bg_green_4: #62B34F;

@font-face {
    font-family: 'Kohinoor';
    src: url('../fonts/KohinoorBangla-Light-05.otf');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Kohinoor';
    src: url('../fonts/KohinoorBangla-Regular-01.otf');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Kohinoor';
    src: url('../fonts/KohinoorBangla-Medium-03.otf');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Kohinoor';
    src: url('../fonts/KohinoorBangla-Semibold-02.otf');
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'Kohinoor';
    src: url('../fonts/KohinoorBangla-Bold-04.otf');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'font-awesome';
    src: url('../fonts/fa-regular-400.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'advent';
    src: url('../fonts/advent-Bd2.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'advent';
    src: url('../fonts/advent-Bd3.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@-webkit-keyframes flash {
    0% {
        opacity: .4;
    }
    100% {
        opacity: 1;
    }
}
@keyframes shine {
    100% {
        left: 125%;
    }
}

* { margin: 0; padding: 0; }

body { font-family: $font-family_kohinoor; margin: 0; }

a {     color: $color_black; text-decoration: none;
&:hover{text-decoration: underline; cursor: pointer}
}

@mixin underline {
    position: relative;
    transition: .2s;
    &::after {
        content: '';
        width: calc(100%);
        display: inline-block;
        position: absolute;
        height: 2px;
        right: 0;
        bottom: 0;
        background-color: $bg_black;
    }
}
@mixin underline_black {
    @include underline;
    &::after {
        background-color: $bg_black;
    }
}
@mixin underline_green {
    @include underline;
    &::after {
        background-color: $bg_green_4;
    }
}


.border {border: 1px $color_black solid;}

@import "./pages.scss";
@import './page-home.scss';
@import './page-realisation-web.scss';
@import './page-realisation-appli.scss';
@import './page-qui-nous-sommes.scss';
@import './page-mentions-legales';
@import './page-map';
@import './page-contact';

@import "./header.scss";
@import './search.scss';
main {
    padding-top: 114px;
}

.souligne-vert {
    border-bottom: #62B34F 3px solid;
    white-space: nowrap;
}

.content {
    max-width: 1600px;
    margin: 0 auto;
    padding-bottom: 2rem;
}
.link {
    color: $color_blue;
    font-weight: bold;
    padding-top: .5rem;
    display: inline-block;
    &:hover {text-decoration: $color_blue underline; cursor: pointer}
    &::after {
        content: ' >';
        color: $color_green;
    }
}
.link-to {
    color: $color_blue;
    font-weight: bold;
    padding-top: .5rem;
    display: inline-block;
    text-decoration: none;
    &:hover {text-decoration: $color_blue underline; cursor: pointer}
    &::before {
        content: '> ';
        color: $color_green;
    }
}


footer {
    padding:2rem 0;
    color: $color_black;
    margin: 0 auto;
    font-size: .95em;
    background-color: $bg_green_2;
    .container {
        white-space: pre;
        max-width: 800px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 0 auto;
        div {flex-basis: 33%; border-right: 2px $color_white solid; min-width: min(40%, 200px)}
        div:nth-of-type(3) {border-right: none}
    }
    ul {display: flex; flex-direction: column;}
    ul li {list-style: none; padding: 0 1.5rem;}
    a {color: $color_black; text-decoration: none;}
}
/* add scss file */
@import "./responsive.scss";