.page.mentions-legales {
    main .content {
        margin-top: 3rem;
        article {
            width: 100%; display: grid;
            grid-template-columns: 400px auto;
            column-gap: 3rem;
            > div {padding: 2rem}
            p {padding: 1rem }

            @media only screen and (max-width: 1023px) {
                grid-template-columns: 200px auto;
            }
        }
    }
}
