
.page {
    .banner {
        background-color: $bg_green_3; padding: 2rem;
        > div { flex-direction: column; align-items: start ; justify-content: start; height: auto;}
        h1, h2 { font-weight: 400; font-family: 'advent', serif; }
        h1 {color: $color_green; }
        h2 {text-align: center;}
        .container {
            display: grid; grid-template-rows: max(2vw, 3rem) auto; grid-template-columns: auto 2fr 1fr;
            grid-gap: 0 3rem;
            align-items: start; justify-content: space-between;
            padding-bottom: 0;
            h1, h2 {
                grid-area: 1 / 1 / 1/ 3;
            }
            h3 { grid-area: 2 / 1 / 2 / 1;
                font-family: 'advent'; ;
                flex-basis: 100px; display: inline-block;
                //font-size: min(4vw, 4rem);
                font-weight: 400;
            }
            .description {
                grid-area: 2 / 2 / 3 / 3;
                padding-top:1rem;
                font-size: max(1.2rem, .7vw);
            }
            p {margin-bottom:1rem; }
            img {
                max-width: min(20vw,350px);
                grid-area: 1 / 3 / 3 / 3;
                position:relative;
                color: blue;
                align-self: start;
                //max-width: min(20vw, 350px) ;
                max-height: 100%;
                object-fit: cover;
                margin-top: min(2rem, 20%);
            }
        }
        display: flex;
        align-items: center;
        justify-content: center;
        h1, h2 { font-size: max(2vw, 3rem); }
        h3 {
            font-size: max(2vw, 3rem);
        }

        > div {
            max-width: 1600px;
            //margin: 0 1rem;
            border-radius: 15% / 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }

    section {
        article {
            .texte {
                display: flex;
                flex-direction: column;
                font-size: min(1.2rem, 1.7vw);
                img.mobile-image { display: none; }
            }

            h1, h2 {
                font-size: min(2.5rem, 4vw);;
                padding-bottom: 2rem;
                font-weight: 500;
            }
            h3 {
                font-size: min(1.5rem, 3vw);
            }
        }
    }

    main .content {
        padding: 0 2rem;
        h1 {font-weight: bold;
            font-size: min(4rem, 4vw);
            font-family: $font-family_advent; padding-bottom: 2rem; }
        article{

            display: grid; grid-template-columns: auto auto; column-gap: 3rem;
            justify-content: start; margin-bottom: 2rem; margin-top: 1rem;

            scroll-margin-top: 6rem;    // anchors & fixed header
            h1, h2 {grid-row: 1 / 1; grid-column: 1 / 3; font-family: $font-family_advent}
            .texte {grid-row: 2 / 2 ; grid-column: 1 / 2}
            aside {grid-row: 1 / 3; grid-column: 2 / 2; margin-bottom: auto;
                img {max-width: 100%; }
            }
            p {  margin-bottom: 1rem; font-size: max(1.2rem, .8vw);}

            ul {margin-left: 1.5rem}
            li {list-style: disc;
                margin-bottom: .5rem;
            }

            &.alternate {
                padding: 2rem; background-color: $bg_green_3;
                h1, h2 {grid-area: 1 / 2 / 1 / 2 ;}
                .texte {grid-row: 2 ; grid-column: 2}
                aside{grid-row: 2 / 2 ; grid-column: 1;}

                &.blue { background-color: $bg_blue_2; }
            }
            &.sep {border-top:1px #DDD solid; padding-top: 2rem; margin-top: 2rem;}

            @media only screen and (max-width: 1023px) {
                &.full {
                    display: block;

                    aside {padding-top: 1rem;
                        display: flex;
                        img {margin: auto; border: 1px gray solid;}
                    }
                }
            }

            &.summary  {
                display: flex; flex-direction: column;
                object {max-width: 800px; margin: auto}

            }
        }
        &.list {
            article {
                display: grid; gap: 2rem; grid-template-columns: min(400px, 30%) auto;
                grid-template-rows: 1fr;
                margin-bottom: 2rem;
                >div:first-of-type {margin: auto}
                .texte {grid-area: auto; }
            }
        }


        &.grid  {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 3rem;
            article {
                grid-template-columns: 1fr;
                grid-template-rows: 350px 1fr;
                margin-bottom: 0;
                figure { display: flex; flex-direction: column; gap: 0; overflow: hidden; height: 310px; overflow: hidden;}

                img {
                    max-width: calc(100% - 2px); object-fit: cover;
                    height: calc(310px - 65px);
                    //min-height: 220px;
                    object-position: top;
                    border: 1px #CCC solid; border-bottom: 0;
                    transition: 0.4s ease;

                    &:hover { transform: scale(1.1);  cursor: pointer;
                        animation: flash .5s;
                    }
                }
                legend {
                    background-color: $bg_white; font-size: calc(10px + 1.2vw);
                    font-family: $font-family_advent;
                    height: 65px;
                    padding: .5rem; z-index: 10;
                }

                &.green legend{background-color: $bg_green_4; color: $bg_white}
                &.green legend .focus { @include underline_black; }
                &.blue legend{background-color: $bg_blue; color: $bg_white}
                &.blue legend .focus { @include underline_green; }
                &.black legend{background-color: $bg_black; color: $bg_white}
                &.black legend .focus { @include underline_green; }
                &.next {background-color: $bg_green_4; color: $bg_white; font-size: 2.5rem; font-family: $font-family_advent;
                    height: 300px; padding: 2rem; box-sizing: border-box; }
                &.next a {color: $bg_white; text-decoration: none; }
                &.next:hover{ transform: scale(0.95);  cursor: pointer;}

                &.next h3 {font-size: 3rem}
            }
        }

        .mobile-img {display: none; }
    }


    .breadcrumb {
        color: $color_green; padding-top:3rem;
        a {text-decoration: none; color: $color_green;}
    }


    @media only screen and (max-width: 1023px) {
        .banner {
            .container {
                justify-content: center;grid-template-rows: auto auto 1fr; align-content: start; min-height: auto;
                h1, h2 { margin: 0; font-size: min(6vw, 4rem);}
                h3 { grid-area: 2 / 1 / 2 / 3; width: 80px; font-size: min(6vw, 4rem);
                    //line-height: min(6vw, 4rem);
                }
                .description{ grid-area: 3 / 1 / 3 / 3;
                    p {display: none}
                    p:first-of-type {display: block;
                        font-size: min(1.1rem, 2.5vw);
                    }
                }
                img {min-width: min(30vw, 300px);
                    max-width: 30vw;
                    grid-area: 1 / 3 / 5 / 3; margin: 0; }
            }

            .container {
                //grid-template-rows: 3rem auto;
                grid-template-columns: auto auto;
                .description {
                    grid-area: 3 / 1 / 4 / 3;
                }
                img {grid-area: 1 / 3 / 4 / 3;}
            }
        }

        main .content {
            &.grid {
                padding: 2rem;
                grid-template-columns: 1fr 1fr;
                article legend { font-size: 2rem; line-height:1.8rem }
            }
            &.list {
                padding: 2rem;
                article {
                    padding: 0;
                    >div:first-of-type {margin: 0; }
                    img {max-width: max(30%, 120px)}
                }
            }
            article { display: block; }
            .texte {
                display: block;

                img {max-width: 30%; float: left; margin: 0 1rem 1rem 0; }
                p, li {margin-bottom:1rem;
                    font-size: min(1.1rem, 3vw);
                }
            }
            .alternate .texte img {float: right; margin: 0 0 1rem 1rem; }
            aside {display: none}
            .mobile-img {display: inline-block; }
        }


    }
    @media only screen and (max-width: 599px) {
        main .content {
            &.grid {
                grid-gap: 1rem; padding: 1rem;
                grid-template-columns: 1fr 1fr;
                article {
                    legend { font-size: 1.2rem; }
                    img {max-height: 200px; min-height: auto; }
                    h3  {font-size: 2rem;}
                }
            }

        }
    }
}