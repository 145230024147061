@font-face {
  font-family: Kohinoor;
  src: url("KohinoorBangla-Light-05.15229c3d.otf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Kohinoor;
  src: url("KohinoorBangla-Regular-01.35a76471.otf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Kohinoor;
  src: url("KohinoorBangla-Medium-03.914d3375.otf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Kohinoor;
  src: url("KohinoorBangla-Semibold-02.f0c6dada.otf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Kohinoor;
  src: url("KohinoorBangla-Bold-04.7c1ac640.otf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: font-awesome;
  src: url("fa-regular-400.93df4952.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: advent;
  src: url("advent-Bd2.5816d0f3.otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: advent;
  src: url("advent-Bd3.3c570ea2.otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@-webkit-keyframes flash {
  0% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Kohinoor;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.border {
  border: 1px solid #000;
}

.page .banner {
  background-color: #eaf6e8;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.page .banner > div {
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: auto;
}

.page .banner h1, .page .banner h2 {
  font-family: advent, serif;
  font-weight: 400;
}

.page .banner h1 {
  color: #62b34f;
}

.page .banner h2 {
  text-align: center;
}

.page .banner .container {
  grid-gap: 0 3rem;
  grid-template-rows: max(2vw, 3rem) auto;
  grid-template-columns: auto 2fr 1fr;
  justify-content: space-between;
  align-items: start;
  padding-bottom: 0;
  display: grid;
}

.page .banner .container h1, .page .banner .container h2 {
  grid-area: 1 / 1 / 1 / 3;
}

.page .banner .container h3 {
  flex-basis: 100px;
  grid-area: 2 / 1 / 2 / 1;
  font-family: advent;
  font-weight: 400;
  display: inline-block;
}

.page .banner .container .description {
  grid-area: 2 / 2 / 3 / 3;
  padding-top: 1rem;
  font-size: max(1.2rem, .7vw);
}

.page .banner .container p {
  margin-bottom: 1rem;
}

.page .banner .container img {
  color: #00f;
  object-fit: cover;
  grid-area: 1 / 3 / 3 / 3;
  align-self: start;
  max-width: min(20vw, 350px);
  max-height: 100%;
  margin-top: min(2rem, 20%);
  position: relative;
}

.page .banner h1, .page .banner h2, .page .banner h3 {
  font-size: max(2vw, 3rem);
}

.page .banner > div {
  border-radius: 15% / 50%;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  display: flex;
}

.page section article .texte {
  flex-direction: column;
  font-size: min(1.2rem, 1.7vw);
  display: flex;
}

.page section article .texte img.mobile-image {
  display: none;
}

.page section article h1, .page section article h2 {
  padding-bottom: 2rem;
  font-size: min(2.5rem, 4vw);
  font-weight: 500;
}

.page section article h3 {
  font-size: min(1.5rem, 3vw);
}

.page main .content {
  padding: 0 2rem;
}

.page main .content h1 {
  padding-bottom: 2rem;
  font-family: advent, serif;
  font-size: min(4rem, 4vw);
  font-weight: bold;
}

.page main .content article {
  grid-template-columns: auto auto;
  justify-content: start;
  column-gap: 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  scroll-margin-top: 6rem;
  display: grid;
}

.page main .content article h1, .page main .content article h2 {
  grid-area: 1 / 1 / 1 / 3;
  font-family: advent, serif;
}

.page main .content article .texte {
  grid-area: 2 / 1 / 2 / 2;
}

.page main .content article aside {
  grid-area: 1 / 2 / 3 / 2;
  margin-bottom: auto;
}

.page main .content article aside img {
  max-width: 100%;
}

.page main .content article p {
  margin-bottom: 1rem;
  font-size: max(1.2rem, .8vw);
}

.page main .content article ul {
  margin-left: 1.5rem;
}

.page main .content article li {
  margin-bottom: .5rem;
  list-style: disc;
}

.page main .content article.alternate {
  background-color: #eaf6e8;
  padding: 2rem;
}

.page main .content article.alternate h1, .page main .content article.alternate h2 {
  grid-area: 1 / 2 / 1 / 2;
}

.page main .content article.alternate .texte {
  grid-area: 2 / 2;
}

.page main .content article.alternate aside {
  grid-area: 2 / 1 / 2;
}

.page main .content article.alternate.blue {
  background-color: #ddddef;
}

.page main .content article.sep {
  border-top: 1px solid #ddd;
  margin-top: 2rem;
  padding-top: 2rem;
}

@media only screen and (width <= 1023px) {
  .page main .content article.full {
    display: block;
  }

  .page main .content article.full aside {
    padding-top: 1rem;
    display: flex;
  }

  .page main .content article.full aside img {
    border: 1px solid gray;
    margin: auto;
  }
}

.page main .content article.summary {
  flex-direction: column;
  display: flex;
}

.page main .content article.summary object {
  max-width: 800px;
  margin: auto;
}

.page main .content.list article {
  grid-template-rows: 1fr;
  grid-template-columns: min(400px, 30%) auto;
  gap: 2rem;
  margin-bottom: 2rem;
  display: grid;
}

.page main .content.list article > div:first-of-type {
  margin: auto;
}

.page main .content.list article .texte {
  grid-area: auto;
}

.page main .content.grid {
  grid-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.page main .content.grid article {
  grid-template-rows: 350px 1fr;
  grid-template-columns: 1fr;
  margin-bottom: 0;
}

.page main .content.grid article figure {
  flex-direction: column;
  gap: 0;
  height: 310px;
  display: flex;
  overflow: hidden;
}

.page main .content.grid article img {
  object-fit: cover;
  object-position: top;
  border: 1px solid #ccc;
  border-bottom: 0;
  max-width: calc(100% - 2px);
  height: 245px;
  transition: all .4s;
}

.page main .content.grid article img:hover {
  cursor: pointer;
  animation: .5s flash;
  transform: scale(1.1);
}

.page main .content.grid article legend {
  z-index: 10;
  background-color: #fff;
  height: 65px;
  padding: .5rem;
  font-family: advent, serif;
  font-size: calc(10px + 1.2vw);
}

.page main .content.grid article.green legend {
  color: #fff;
  background-color: #62b34f;
}

.page main .content.grid article.green legend .focus {
  transition: all .2s;
  position: relative;
}

.page main .content.grid article.green legend .focus:after {
  content: "";
  background-color: #000;
  width: 100%;
  height: 2px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.page main .content.grid article.blue legend {
  color: #fff;
  background-color: #2e3192;
}

.page main .content.grid article.blue legend .focus {
  transition: all .2s;
  position: relative;
}

.page main .content.grid article.blue legend .focus:after {
  content: "";
  background-color: #62b34f;
  width: 100%;
  height: 2px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.page main .content.grid article.black legend {
  color: #fff;
  background-color: #000;
}

.page main .content.grid article.black legend .focus {
  transition: all .2s;
  position: relative;
}

.page main .content.grid article.black legend .focus:after {
  content: "";
  background-color: #62b34f;
  width: 100%;
  height: 2px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.page main .content.grid article.next {
  color: #fff;
  box-sizing: border-box;
  background-color: #62b34f;
  height: 300px;
  padding: 2rem;
  font-family: advent, serif;
  font-size: 2.5rem;
}

.page main .content.grid article.next a {
  color: #fff;
  text-decoration: none;
}

.page main .content.grid article.next:hover {
  cursor: pointer;
  transform: scale(.95);
}

.page main .content.grid article.next h3 {
  font-size: 3rem;
}

.page main .content .mobile-img {
  display: none;
}

.page .breadcrumb {
  color: #62b34f;
  padding-top: 3rem;
}

.page .breadcrumb a {
  color: #62b34f;
  text-decoration: none;
}

@media only screen and (width <= 1023px) {
  .page .banner .container {
    grid-template-rows: auto auto 1fr;
    place-content: start center;
    min-height: auto;
  }

  .page .banner .container h1, .page .banner .container h2 {
    margin: 0;
    font-size: min(6vw, 4rem);
  }

  .page .banner .container h3 {
    grid-area: 2 / 1 / 2 / 3;
    width: 80px;
    font-size: min(6vw, 4rem);
  }

  .page .banner .container .description p {
    display: none;
  }

  .page .banner .container .description p:first-of-type {
    font-size: min(1.1rem, 2.5vw);
    display: block;
  }

  .page .banner .container img {
    grid-area: 1 / 3 / 5 / 3;
    min-width: min(30vw, 300px);
    max-width: 30vw;
    margin: 0;
  }

  .page .banner .container {
    grid-template-columns: auto auto;
  }

  .page .banner .container .description {
    grid-area: 3 / 1 / 4 / 3;
  }

  .page .banner .container img {
    grid-area: 1 / 3 / 4 / 3;
  }

  .page main .content.grid {
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
  }

  .page main .content.grid article legend {
    font-size: 2rem;
    line-height: 1.8rem;
  }

  .page main .content.list {
    padding: 2rem;
  }

  .page main .content.list article {
    padding: 0;
  }

  .page main .content.list article > div:first-of-type {
    margin: 0;
  }

  .page main .content.list article img {
    max-width: max(30%, 120px);
  }

  .page main .content article, .page main .content .texte {
    display: block;
  }

  .page main .content .texte img {
    float: left;
    max-width: 30%;
    margin: 0 1rem 1rem 0;
  }

  .page main .content .texte p, .page main .content .texte li {
    margin-bottom: 1rem;
    font-size: min(1.1rem, 3vw);
  }

  .page main .content .alternate .texte img {
    float: right;
    margin: 0 0 1rem 1rem;
  }

  .page main .content aside {
    display: none;
  }

  .page main .content .mobile-img {
    display: inline-block;
  }
}

@media only screen and (width <= 599px) {
  .page main .content.grid {
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
  }

  .page main .content.grid article legend {
    font-size: 1.2rem;
  }

  .page main .content.grid article img {
    min-height: auto;
    max-height: 200px;
  }

  .page main .content.grid article h3 {
    font-size: 2rem;
  }
}

.page.home .banner {
  background-color: #2e3192;
}

.page.home .banner > div {
  background-color: #fff;
  flex-direction: row;
  width: 90%;
  padding: 1em 3rem 1rem 100px;
}

.page.home .banner h2, .page.home .banner img {
  margin: auto;
}

.page.home .banner object {
  object-fit: contain;
  width: min(80vw, 600px);
  height: 100%;
  display: flex;
}

.page.home .banner .focus {
  color: #62b34f;
  position: relative;
}

.page.home .banner .focus:after {
  content: "";
  background-color: #000;
  width: calc(100% - 6vw);
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.page.home .content section {
  padding-bottom: 1rem;
}

.page.home .content article {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  max-height: calc(400px + min(40vw, 200px) / -2 - 1rem);
  display: flex;
  position: relative;
}

.page.home .content article .texte {
  width: 70%;
}

.page.home .content article:nth-child(odd) .line-project {
  background-image: url("branch-a.d27f75da.svg");
  left: calc(15% - 60px);
}

.page.home .content article:nth-child(2n) .line-project {
  background-image: url("branch-b.a5563e82.svg");
}

.page.home .content article .line-project {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: calc(400px + -1 * max(5vw, 150px) - 1rem);
  position: absolute;
  top: calc(max(6vw, 200px) - 2rem);
  left: calc(15% - 60px);
}

.page.home .content article .texte {
  flex-direction: column;
  font-size: min(7px + .7vw, 1.5rem);
  display: flex;
}

.page.home .content article h1, .page.home .content article h2 {
  padding-bottom: 1rem;
  font-family: advent, serif;
  font-size: min(4vw, 3rem);
  font-weight: 500;
}

.page.home .picto {
  z-index: 2;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: max(6vw, 140px);
  flex-basis: 20%;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 200px;
}

.page.home .picto.outils {
  background-image: url("outils_1.a95ccdc6.svg");
}

.page.home .picto.outils:hover {
  background-image: url("outils_2.4f7601d8.svg");
}

.page.home .picto.collab {
  background-image: url("mains_1.0780ea4a.svg");
}

.page.home .picto.collab:hover {
  background-image: url("mains_2.fb8d05f0.svg");
}

.page.home .picto.eureka {
  background-image: url("solutions-originales_1.178068d3.svg");
}

.page.home .picto.eureka:hover {
  background-image: url("solutions-originales_2.5fd5f051.svg");
}

.page.home .picto.user {
  background-image: url("specialistes_1.aac06183.svg");
}

.page.home .picto.user:hover {
  background-image: url("specialistes_2.6d04d50d.svg");
}

@media only screen and (width <= 1023px) {
  .page.home .banner {
    padding: 1rem;
  }

  .page.home .banner > div {
    border-radius: 10%;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0;
  }

  .page.home .banner > div h2 {
    text-align: center;
    order: 2;
    margin: 1rem 0;
    font-size: max(5vw, 16px);
  }

  .page.home .banner > div > * {
    margin: auto;
  }

  .page.home .banner > div img {
    width: auto;
  }

  .page.home .picto {
    back-ground-size: 0 2rem;
    background-origin: content-box;
  }

  .page.home .content {
    padding: 0 1rem;
  }

  .page.home section article h2 {
    font-size: 2rem;
  }

  .page.home section article .texte {
    font-size: 1rem;
  }
}

@media only screen and (width <= 600px) {
  .page.home section.content article {
    column-gap: 1rem;
    height: auto;
  }

  .page.home .picto {
    background-size: 100%;
  }

  .page.home .line-project {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15vw;
    height: 25vh;
    position: absolute;
    top: 16vh;
    left: 5vw !important;
  }
}

@media screen and (width <= 1024px) {
  .page.realisations-web .banner .container h3 {
    width: 40px;
  }
}

.page.realisation .banner {
  box-sizing: border-box;
  background-color: #2e3192;
  height: 500px;
  padding-top: 0;
}

.page.realisation .banner .container {
  width: 1600px;
  height: 100%;
  margin: 0;
  padding: 70px 3rem 0;
  display: block;
}

.page.realisation .banner img {
  object-fit: cover;
  object-position: top left;
  width: 100%;
  max-width: none;
  height: 110%;
  max-height: calc(600px - 5rem);
  margin-top: 0;
  padding: 0;
  position: relative;
  box-shadow: 0 0 10px #00000080;
}

.page.realisation main .content {
  padding: 3rem;
}

.page.realisation main .content h1 {
  padding-bottom: 2rem;
  font-family: advent, serif;
  font-weight: bold;
}

.page.realisation main .content article {
  grid-template-rows: auto 5fr;
}

.page.realisation main .content article.alternate {
  grid-template-rows: auto 5fr;
  grid-template-columns: 1fr 2fr;
  padding: 2rem;
}

.page.realisation main .content article.alternate h1 {
  grid-area: 1 / 2 / 1 / 2;
}

.page.realisation main .content article.alternate .texte {
  grid-area: 2 / 2 / 3 / 2;
}

.page.realisation main .content article.alternate aside {
  grid-area: 1 / 1 / 3 / 2;
}

.page.realisation main .content article.summary {
  flex-direction: column;
  display: flex;
}

.page.realisation main .content article.summary img {
  max-width: 800px;
  margin: auto;
}

@media screen and (width <= 1024px) {
  .page.realisation .banner {
    height: min(500px, 30vh);
    padding: 0;
  }

  .page.realisation .banner .container {
    margin: 0;
    padding: 70px 1rem 0;
  }

  .page.realisation .banner img {
    object-fit: cover;
    height: 100%;
    position: relative;
  }

  .page.realisation main .content {
    padding: 2rem;
  }

  .page.realisation main .content article {
    flex-flow: column wrap;
    display: flex;
  }

  .page.realisation main .content article.alternate {
    padding: 1rem;
  }

  .page.realisation main .content article aside {
    width: 100%;
    padding-top: 1rem;
  }

  .page.realisation main .content article aside img {
    width: 100%;
  }
}

@media (orientation: landscape) {
  .page.realisation .banner {
    height: 500px;
    padding: 0;
  }
}

.page.realisations-appli .grid.appli {
  grid-template-columns: 1fr 1fr;
}

.page.realisations-appli .grid.appli article {
  flex-direction: column;
  display: flex;
}

.page.realisations-appli .grid.appli article aside {
  grid-area: auto;
  margin: auto;
  padding: 0;
}

.page.realisations-appli .grid.appli article img {
  object-fit: contain;
  object-position: bottom;
  border: 0;
  max-width: max(500px, 30vw);
}

@media screen and (width <= 1024px) {
  .page.realisations-appli .grid.appli {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .page.realisations-appli .grid.appli article:first-of-type > div:first-of-type {
    border-top: 0;
  }

  .page.realisations-appli .grid.appli article > div:first-of-type {
    border-top: 1px solid gray;
    margin: auto;
    padding: 1rem 0 0;
  }

  .page.realisations-appli .grid.appli article img {
    object-position: top;
    max-width: 40%;
  }

  .page.realisations-appli .grid.appli article h3 {
    font-size: 1.5rem;
    font-weight: normal;
  }
}

.page.qui-nous-sommes main .content article {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.page.qui-nous-sommes main .content article aside {
  flex: 0 300px;
}

.page.qui-nous-sommes main .content article .texte {
  flex: 1;
}

.page.mentions-legales main .content {
  margin-top: 3rem;
}

.page.mentions-legales main .content article {
  grid-template-columns: 400px auto;
  column-gap: 3rem;
  width: 100%;
  display: grid;
}

.page.mentions-legales main .content article > div {
  padding: 2rem;
}

.page.mentions-legales main .content article p {
  padding: 1rem;
}

@media only screen and (width <= 1023px) {
  .page.mentions-legales main .content article {
    grid-template-columns: 200px auto;
  }
}

.page.plan main article {
  grid-template-columns: 1fr auto;
  max-width: 700px;
  margin: 3rem auto;
}

.page.plan main a {
  text-decoration: underline;
}

.page.plan main li {
  color: #00008b;
}

.contact-form {
  background-color: #f9f9f9;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin: 3rem 0;
  padding: 15px;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.contact-form .form-group input, .contact-form .form-group textarea {
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  width: calc(100% - 15px);
  padding: 10px;
}

.contact-form .form-group button {
  color: #fff;
  cursor: pointer;
  background-color: #2e3192;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
}

.contact-form .form-group button:hover {
  background-color: #2e3192;
}

.contact-results:not(:empty) {
  border: 1px solid #aaa;
  padding: 1rem;
}

.contact-results.error {
  color: #8b0000;
  background-color: #fee;
}

.contact-results.success {
  color: #006400;
  background-color: #efe;
}

.contact-results a {
  color: inherit;
  text-decoration: underline;
}

.scrolled header {
  border-bottom: 1px solid #ccc;
  transition: all .5s;
}

.scrolled header .logo img {
  height: 75px;
  transition-duration: .2s;
}

.opened main, .opened footer {
  display: none;
}

header {
  z-index: 30;
  background-color: #fff;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  position: fixed;
  box-shadow: 1px 1px 4px #0000001a;
}

header nav {
  background-color: #fff;
  grid-template-columns: 300px auto;
  width: 100%;
  max-width: 1600px;
  margin: auto;
  padding: 0 2rem;
  display: grid;
}

header nav a:hover {
  text-decoration: none;
}

header nav li {
  width: 100%;
}

header nav li a {
  color: #000;
  font-size: min(.9vw, 1rem);
  font-weight: 600;
  text-decoration: none;
  transition: all .6s;
}

header nav .summary a {
  font-size: 1.2rem;
}

header nav .sub {
  opacity: 0;
  height: 0;
  display: none;
}

header nav .sub a {
  display: block;
}

header nav .focus {
  opacity: 0;
  height: 0;
  display: none;
}

header nav .active .sub {
  opacity: 1;
  height: auto;
  transition: all .5s ease-in-out;
  display: flex;
}

header nav .active .sub a {
  font-weight: 400;
}

header nav .active .sub a:hover {
  font-weight: 600;
}

header nav .active .sub ul {
  flex-direction: column;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

header nav .active .sub ul li:before {
  content: " - ";
  padding-right: .2rem;
}

header nav .active .focus {
  opacity: 1;
  max-width: calc(100% - 4rem);
  height: auto;
  transition: all .5s ease-in-out;
  display: flex;
}

header nav .active > a {
  color: #2e3192;
  position: relative;
}

header nav .focus h3 {
  color: #62b34f;
  font-variant: small-caps;
  padding-bottom: .2rem;
  font-size: 1.2rem;
}

header nav .focus p {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

header nav .focus a {
  font-size: .8rem;
}

header nav ul {
  background-color: #fff;
  align-items: center;
  font-size: .9em;
  list-style: none;
  display: flex;
}

header nav ul li a {
  color: #000;
  text-decoration: none;
  transition: all .2s;
}

header nav ul li.contact-search {
  gap: 0 2rem;
  padding: 0 1rem;
  display: flex;
}

header nav .menu-www {
  grid-area: 1 / 2 / 2 / 5;
  justify-items: start;
  height: 5rem;
}

header nav .menu-www li {
  text-align: center;
}

header nav .menu-www .current a {
  color: #2e3192;
  font-weight: 700;
}

header nav .menu-www .active a:after {
  content: "";
  border-bottom: 3px solid #62b34f;
  width: calc(100% - 3rem);
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 3rem;
}

header nav .menu-www :not(.active):not(.current) a {
  color: #000;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

header nav .menu-www :not(.active):not(.current) a:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

header nav .menu-www :not(.active):not(.current) a:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: #000;
  width: calc(100% - 1rem);
  height: .1rem;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 1rem;
  transform: scaleX(0);
}

header nav .submenu-www {
  flex-direction: column;
  grid-area: 2 / 2 / 2 / span 3;
  display: none;
}

header nav .submenu-www > .active {
  margin-bottom: 2rem;
  transition: all .2s ease-in-out;
  display: flex;
}

header nav .submenu-www > .active .sub {
  flex-flow: column;
  flex: 1;
  justify-items: start;
  padding: 0 3rem;
  display: flex;
}

header nav .submenu-www > .active .sub a:hover {
  color: #2e3192;
}

header nav .submenu-www > .active .focus {
  border-top: 0;
  border-left: 2px solid #000;
  flex-direction: row;
  flex: 3;
  margin-right: auto;
  padding: 0 4rem;
}

header nav .submenu-www > .active .focus .summary {
  text-align: right;
  max-width: 300px;
  padding-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

header .logo {
  text-align: start;
  flex-basis: 250px;
  grid-area: 1 / 1 / 3 / 1;
  font-size: 2em;
  text-decoration: none;
}

header .logo img {
  max-width: 100%;
  height: 100%;
  max-height: 100px;
}

header .burger {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  grid-area: 1 / 1;
  align-self: center;
  justify-items: start;
  gap: .5rem;
  max-width: 100px;
  margin-right: auto;
  display: flex;
}

header .burger.open .navicon:first-of-type {
  opacity: 0;
  transition: opacity;
}

header .burger.open .navicon:nth-of-type(2) {
  top: 5px;
  transform: rotate(-45deg)scaleY(1.1);
}

header .burger.open .navicon:last-of-type {
  bottom: 6px;
  transform: rotate(45deg);
}

header .burger .navicon {
  background: #333;
  width: 40px;
  height: 4px;
  margin: auto;
  transition: all .3s cubic-bezier(.8, .5, .2, 1.4);
  display: block;
  position: relative;
}

header .menu-mobile {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  grid-area: 2 / 1 / auto / span 3;
  justify-content: start;
  padding: 2rem;
  display: flex;
}

header .menu-mobile .sub {
  flex-direction: column;
  padding: 0 0 2rem 8rem;
}

header .menu-mobile .sub a {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: normal;
  display: inline-block;
}

header .menu-mobile .focus {
  flex-direction: column;
  padding-top: 1rem;
}

header .menu-mobile .summary p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
}

header .menu-mobile a {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  display: inline-block;
}

header .menu-mobile .sub {
  padding: 0 0 2rem 2rem;
}

header .menu-mobile .sub a {
  font-size: 1.2rem;
}

header .menu-mobile .active > a:after {
  content: "";
  border-bottom: 3px solid #62b34f;
  width: calc(100% - 3rem);
  height: 3px;
  position: absolute;
  top: 2.5rem;
  left: 3rem;
}

header .search-mobile {
  grid-area: 1 / 3;
  align-self: center;
  margin-left: auto;
}

@media only screen and (width <= 1023px) {
  header {
    width: 100vw;
    height: 5rem;
  }

  header nav {
    grid-template-columns: 120px 1fr 1fr;
  }

  header .burger.open .menu {
    display: flex;
  }

  header .logo {
    text-align: left;
    grid-area: 1 / 2;
    max-height: 90%;
    margin: auto 0;
  }

  header .menu-www:not(.open), header .menu-mobile:not(.open) {
    display: none;
  }

  nav {
    grid-template-columns: 100px 1fr 100px;
  }

  nav ul {
    font-size: .7em;
  }
}

@media only screen and (width <= 599px) {
  .scrolled header .logo img {
    height: auto;
  }

  header .burger .navicon {
    height: 3px;
  }

  .search-mobile i {
    font-size: 2rem;
  }
}

.search-panel {
  background-color: #eaf6e8;
  padding-top: 121px;
  display: none;
}

.show-search .search-panel {
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 121px);
  transition: width .2s;
  display: flex;
}

.show-search .search-panel form {
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  display: flex;
}

.show-search .search-panel .action.close {
  align-self: end;
  padding: 2rem 1rem;
  top: 50px;
}

.show-search .search-panel label {
  flex-basis: 100%;
  margin-bottom: 2rem;
  font-weight: 400;
  display: block;
}

.show-search .search-panel input[type="text"] {
  color: #a9a9a9;
  border-color: #0000;
  flex: 1;
  width: 100%;
  margin: 0;
  padding: 8px;
  font-size: max(2rem, 1.2vw);
  line-height: 2rem;
}

.show-search .search-panel .action.search {
  color: #fff;
  cursor: pointer;
  background-color: #9dcf98;
  padding: 10px 20px;
  font-size: 2rem;
  line-height: 3rem;
  display: inline-block;
}

.show-search .search-panel .action.search:hover {
  background-color: #9dcf98;
}

.show-search .search-results ul {
  margin-top: 2rem;
}

.show-search .search-results ul li {
  line-height: 2rem;
  list-style: none;
}

.show-search .search-results ul a {
  text-decoration: none;
}

.show-search .search-results ul a:hover {
  text-decoration: underline;
}

.show-search main, .show-search footer {
  display: none;
}

@media only screen and (width <= 1023px) {
  .show-search .search-panel {
    width: auto;
  }

  .show-search form {
    max-width: 100%;
    padding: 1rem;
  }

  .show-search .search-results {
    padding: 0 2rem;
  }
}

main {
  padding-top: 114px;
}

.souligne-vert {
  white-space: nowrap;
  border-bottom: 3px solid #62b34f;
}

.content {
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.link {
  color: #2e3192;
  padding-top: .5rem;
  font-weight: bold;
  display: inline-block;
}

.link:hover {
  cursor: pointer;
  -webkit-text-decoration: underline #2e3192;
  text-decoration: underline #2e3192;
}

.link:after {
  content: " >";
  color: #62b34f;
}

.link-to {
  color: #2e3192;
  padding-top: .5rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.link-to:hover {
  cursor: pointer;
  -webkit-text-decoration: underline #2e3192;
  text-decoration: underline #2e3192;
}

.link-to:before {
  content: "> ";
  color: #62b34f;
}

footer {
  color: #000;
  background-color: #a9d69e;
  margin: 0 auto;
  padding: 2rem 0;
  font-size: .95em;
}

footer .container {
  white-space: pre;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 800px;
  margin: 0 auto;
  display: grid;
}

footer .container div {
  border-right: 2px solid #fff;
  flex-basis: 33%;
  min-width: min(40%, 200px);
}

footer .container div:nth-of-type(3) {
  border-right: none;
}

footer ul {
  flex-direction: column;
  display: flex;
}

footer ul li {
  padding: 0 1.5rem;
  list-style: none;
}

footer a {
  color: #000;
  text-decoration: none;
}

@media (width >= 48em) {
  .header .menu-www {
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}

@media only screen and (width <= 1023px) {
  .banner > div {
    border-radius: 40% / 50%;
    flex-direction: column;
    height: 100%;
  }

  .banner > div img {
    max-width: none;
  }

  .banner > div h2 {
    margin-bottom: 2rem;
    font-size: 3rem;
  }

  header {
    height: 5rem;
  }

  main {
    padding-top: 7rem;
  }

  main .content {
    padding: 1rem;
  }

  footer {
    margin: 1rem 0;
  }

  footer .container {
    grid-template-columns: 1fr 1fr;
    padding: .5rem 1rem;
    font-size: .85rem;
  }

  footer .container div {
    padding-left: 1rem;
  }

  footer .container div:first-of-type {
    grid-row: auto / span 2;
  }

  footer .container div:nth-of-type(2) {
    border-right: none;
  }

  footer .container div:nth-of-type(3) {
    margin-top: auto;
  }

  footer .container a {
    line-height: 1.5rem;
    display: inline-block;
  }

  footer .container li {
    white-space: normal;
    padding: 0 .5rem;
  }
}

@media only screen and (width <= 480px) {
  nav figure img {
    object-fit: contain;
    width: 100px;
    height: 100px;
  }

  main {
    padding-top: 5rem;
  }

  section article h2 {
    font-size: 1.5rem;
  }

  section article .texte {
    font-size: 1rem;
  }
}

@media only screen and (width >= 1024px) {
  header .burger {
    display: none;
  }

  header .menu-www, header nav .submenu-www {
    display: flex;
  }

  header .menu-mobile, header .search-mobile {
    display: none;
  }

  .picto {
    background-size: 200px;
  }
}

@media only screen and (width >= 1600px) {
  .header li a {
    font-size: 16px;
  }

  .page .banner h2 {
    font-size: 60px;
  }

  .page.realisations .banner .content h3 {
    font-size: 50px;
  }

  .page.realisations .banner .content h3 section article .texte {
    font-size: 20px;
  }

  .page.realisations .banner .content h3 section article h2 {
    font-size: 34px;
    line-height: 4rem;
  }
}

/*# sourceMappingURL=index.e30c3a17.css.map */
