$color_1: white;
$background-color_1: #f9f9f9;
$background-color_2: #2e3192;

.contact-form {
    padding: 15px;
    background-color: $background-color_1;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    margin: 3rem 0 ;
    .form-group {
        margin-bottom: 15px;
        label {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
        }
        input {
            width: calc(100% - 15px);
            padding: 10px;
            border: 1px solid #e9e9e9;
            border-radius: 3px;
        }
        textarea {
            width: calc(100% - 15px);
            padding: 10px;
            border: 1px solid #e9e9e9;
            border-radius: 3px;
        }
        button {
            padding: 10px 20px;
            background-color: $background-color_2;
            color: $color_1;
            border: none;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
                background-color: $background-color_2;
            }
        }
    }
}

.contact-results {
    &:not(:empty) { border: 1px solid #AAA; padding: 1rem; }
    &.error {color:darkred; background-color: #fEE;}
    &.success {color:darkgreen; background-color: #efE;}
    a {color:inherit; text-decoration:underline;}
}
