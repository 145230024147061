.page.qui-nous-sommes {
    main .content {
        article {
            width: 100%; display: flex; justify-content: center;
            align-items: center; position: relative;
            aside {flex: 0 300px;}
            .texte {flex: 1;}
        }
    }
}
